import React, {useMemo, SetStateAction, useContext} from 'react';
import styles from './styles/FilesFoldersBlock.module.scss';
import classNames from 'classnames';
import FontAwesome from "../../../utilities/FontAwesome";
import FilesListHeader from "./FilesListHeader";
import CleverTable from "../../../utilities/CleverTable/CleverTable";
import ImageComponent from "../../../utilities/ImageComponent";
import {getIconByMimeType, getIconSet} from "./fileUtilities";
import moment from "moment";
import ClickableLink from "../../../utilities/ClickableLink";
import {observer} from "mobx-react";
import {StoreContext} from "../../../../stores/StoreLoader";
import {EditButton} from "../EditButton";
import {canPreviewContentItem} from "./FilesFoldersBlock";
import {IReactiveFileContentItem, IReactiveFolderContentItem} from "../../../../stores/SchoolFeedStore";

const transitionClassNames = {
    enter: styles.transitionEnter,
    enterActive: styles.transitionEnterActive,
    enterDone: styles.transitionEnterDone,
    exit: styles.transitionExit,
    exitActive: styles.transitionExitActive,
    exitDone: styles.transitionExitDone,
};

const FilesList = observer((props: {
    files_folders: Array<IReactiveFileContentItem | IReactiveFolderContentItem>,
    setSelectedContentItem?: SetStateAction<any>,
    setPreviewIsExpanded?: SetStateAction<any>,
    previewIsExpanded?: boolean,
    selectedItem?: FileContentItem | FolderContentItem,
    aboveTheFold: boolean,
    handleFetchMore?: () => void,
    shouldFetchMore?: boolean,
    maxHeight?: number | string,
    useInfiniteScroll?: boolean,
    handleDelete?: (contentItem: ContentItem) => void,
}) => {
    const {organizationStore, modalStore, interfaceStore, userStore} = useContext(StoreContext);

    const filesContainerClassName = classNames({
        [styles.filesSliderContainer]: true,
        [styles.filesSliderContainerBackgroundImage]: !!organizationStore.currentOrganization.backgroundImage,
    });
    const expandButtonClassName = classNames({
        [styles.expandPreviewButton]: true,
        [styles.expandPreviewButtonNotExpanded]: !props.previewIsExpanded,
    })

    const expandButtonContainerClassName = classNames({
        [styles.expandedPreviewButtonContainer]: true,
        [styles.expandedPreviewButtonContainerNotEditor]: !userStore.isEditor,
    })


    const columns = [{
        title: <FilesListHeader name={'Name'} />,
        dataKey: 'name',
        initialWidth: 100,
    }]



    return (
        <CleverTable
            columns={columns}
            transitionIn={props.aboveTheFold}
            useInfiniteScroll={props.useInfiniteScroll}
            transitionClassNames={transitionClassNames}
            data={props.files_folders.map((file, idx) => {
                const fileOrigin = getIconSet(file.type);
                const fileOrFolderSettings = file.is_file ? (file as FileContentItem).json_data.file : (file as FolderContentItem).json_data.folder;
                const iconUrl = getIconByMimeType(fileOrigin, file.is_folder ? 'application/vnd.google-apps.folder' : file.json_data.file?.mime_type);
                const shouldOpenPreview = canPreviewContentItem(file, organizationStore.organization);
                const tableCellClassName = classNames({
                    [styles.tableCell]: true,
                    [styles.tableCellHidden]: !file.published,
                });
                return {
                  name: (
                      <div className={tableCellClassName}>
                          <ImageComponent src={iconUrl} />
                          <div className={classNames(styles.titleContainer, {
                              [styles.titleContainerSelected]: props.selectedItem?.id === file.id,
                          })}>
                              {shouldOpenPreview ? (
                                  <span>{file.title}</span>
                      ) : (
                          <ClickableLink href={fileOrFolderSettings?.view_link}>
                              <span>{file.title}</span>
                              <FontAwesome
                            name={"fa-external-link-alt"}
                            prefix={"fas"}
                          />
                          </ClickableLink>
                      )}
                          </div>
                          {props.selectedItem?.id === file.id &&
                              <div className={expandButtonContainerClassName}>
                                  <button
                                  className={expandButtonClassName}

                                  onClick={props.previewIsExpanded ? () => props.setPreviewIsExpanded(false) : () => props.setPreviewIsExpanded(true)}
                              >

                                      <FontAwesome
                                      name={props.previewIsExpanded ? "fa-down-left-and-up-right-to-center" :"fa-up-right-and-down-left-from-center"}
                                      prefix={"fas"}
                                  /> {props.previewIsExpanded ? "Shrink":"Expand" }

                                  </button>
                              </div>
                          }
                          {userStore.isEditor && props.handleDelete && (
                              <div className={styles.editButton}>
                                  <EditButton
                            contentItem={file}
                            handleDelete={props.handleDelete}
                          />

                              </div>
                      )}
                          <div className={styles.description}>
                              {file.description}
                          </div>
                      </div>
                  ),
                  modified: (
                      <div className={tableCellClassName}>
                          {file.is_file ? (
                        moment(
                          file.is_custom
                            ? file.updated_at
                            : fileOrFolderSettings.updated_at
                        ).fromNow()
                      ) : (
                          <p></p>
                      )}
                      </div>
                  ),
                  onClick: shouldOpenPreview
                    ? () => {
                        if (interfaceStore.dimensions.width < 1000 || !props.setSelectedContentItem) {
                          modalStore.addModal({
                            type: "file-preview",
                            id: file.id,
                            itemObj: file,
                          });
                        } else {
                          props.setSelectedContentItem(file);
                          if (file.id !== props.selectedItem?.id) {
                            props.setPreviewIsExpanded(false);
                          }

                        }
                      }
                    : false,
                  key: file.id,
                  style: {
                    transitionDelay: `${idx * 0.15}s`,
                  },
                    height: file.description ? 75 : 50,
                };
            })}
            className={filesContainerClassName}
            tableRowClassName={styles.tableRow}
            noDataMessage={"No files or folders found."}
            initialWidthIsPercent={true}
            selectedItemKey={props.selectedItem?.id}
            handleFetchMore={props.handleFetchMore}
            shouldFetchMore={props.shouldFetchMore}
            maxHeight={props.maxHeight}
        />
    );
});

export default FilesList;
